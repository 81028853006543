@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

:root {
  --main-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  --main-family-x: 'Poppins', sans-serif;
  --color-bg: linear-gradient(180deg, #FFFFFF 0%, #AEC6DC 100%);
}

body {
  margin: 0;
  height: 100%;
  background: var(--color-bg);
  background-size: cover;
  font-family: var(--main-family-x);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}
