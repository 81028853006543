.event-logo {
  max-height: 300px;
}

.event-details {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.event-title {
  font-size: max(4vw, 24px);
  margin: 20px;
}