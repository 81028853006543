.footer-content {
  margin-bottom: 10px;
}

.footer-items {
  text-align: center;
}

.footer-info * {
  margin: 10px;
  cursor: pointer;
}

.footer-contact * {
  margin: 10px;
}