.event {
  display: flex;
  justify-content: center;
  align-items: center; 
  flex-direction: column;
  min-height: 100vh; 
}

.event-payment-steps {
  max-width: 650px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px;
  /* box-sizing: border-box; */
}

.event-program {
  width: 100%;
}

.event-footer {
  margin-top: auto;
}

@media only screen and (max-width: 650px) {
  .event {
    justify-content: flex-start;
  }
}