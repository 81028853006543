.event-form-next {
  display: contents;
}

.event-form-modal-link {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}

.ticket-count {
  width: 100%;
}