.admin {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
}

.admin-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}
