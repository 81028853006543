.ticket-verify {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
}

.ticket-verify-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.ticket-verify-unused-ticket {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 10px;
  left: 10px;
}

.ticket-verify-update-status-button {
  position: absolute;
  bottom: 50px;
}

.ticket-verify-icon {
  font-size: 70px;
}

.ticket-verify-icon-valid {
  color: forestgreen;
}

.ticket-verify-icon-invalid {
  color: crimson;

}

.ticket-verify-icon-used {
  color: orange;

}

.ticket-verify-icon-loading {
  color: dimgray;
}