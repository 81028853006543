.event-sponsors img {
  margin: 20px;
  max-height: 80px;
}

@media screen and (max-width: 575px) {
  .event-sponsors img {
    margin: 10px;
    max-height: 50px;
  }
}