.payment-status {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}

.payment-status-steps {
  max-width: 650px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px;
  height: 300px;
}

.payment-status-message {
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.payment-status-message-description {
  display: flex;
  justify-content: center;
}

.payment-status-icon {
  display: none;
}

.payment-succeeded {
  color: forestgreen;
}

.payment-failed {
  color: crimson;
}

.payment-canceled {
  color: orange;
}

.payment-status-header {
  margin-bottom: 20px;
}

@media screen and (max-width: 650px) {
  .payment-status-steps {
    width: 90%;
  }
}

@media screen and (max-width: 575px) {
  .payment-status-steps {
    width: 90%;
  }

  .payment-status-message {
    height: 25%;
  }

  .payment-status-icon {
    display: block;
    margin-bottom: 70px;
    font-size: 70px;
  }

}